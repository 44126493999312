import React from "react";
import '../Userinput/Userinput.css';
import { useNavigate } from 'react-router-dom';

const Userinput = () => {
    const navigate = useNavigate();

    return (
        <div className="userinputcontainer">
            <div className="userinput_backbutton">
                <button onClick={() => navigate(-1)} className="userinput_review-back-button">
                    <i class="fa-solid fa-arrow-left"></i>
                </button>
            </div>
            <div className="userinput_view_container">
                <h2 className="userinput_profile_head">profile</h2>
            </div>
            <div className="userinput_image_container">
                <img src='./card.png' alt="Profile" className="userinput_image" />
            </div>
            <div className="form_profile">
                <input placeholder="Full Name" class="inputprofile" name="text" type="text"></input>
                <p className="pdate">Mobile no</p>
                <input placeholder="+91 1234567890" class="inputprofile" name="text" type="number"></input>
                <p className="pdate">date of birth</p>
                <input placeholder="Date of birth" class="inputprofile" name="text" type="date"></input>

            </div>
            <button type="submit" className="inputprofile_button"> Next  </button>
        </div>
    );

};
export default Userinput;