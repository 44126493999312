import React from "react";
import '../Rateandreview/Rateandreview.css';
import { useNavigate } from 'react-router-dom';

const Rateandreview = () => {

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
<div className="container1111">
    <div className="backbutton">
    <button onClick={handleBackClick} className="review-back-button">
                        <i class="fa-solid fa-arrow-left"></i>
                     </button>
    </div>
<div className="review_container">
                     
        <h2 className="profile_head">Rate and review</h2>
        </div>
        <div className="rating-container">
        <div class="rating">

                    <input value="5" name="rating" id="star5" type="radio"/>
                    <label for="star5"></label>
                    <input value="4" name="rating" id="star4" type="radio"/>
                    <label for="star4"></label>
                    <input value="3" name="rating" id="star3" type="radio"/>
                    <label for="star3"></label>
                    <input value="2" name="rating" id="star2" type="radio"/>
                    <label for="star2"></label>
                    <input value="1" name="rating" id="star1" type="radio"/>
                    <label for="star1"></label>
       </div>
    </div>   
    
    <div className="rating-description">
      <p className="rating-head">Please tell us your experience below</p>
      <textarea class="feedback-textarea" rows="4" placeholder="Enter your feedback here..."></textarea>
      <button className="review_button"> Submit</button>
    </div>
    
</div>
        );
    };
    export default Rateandreview;