
import './App.css';
import Login from './components/Login/Login';
import Homepage from './components/Homepage/Homepage';
import Detailpage from './components/Detailpage/Detailpage';
import Profile from './components/Profile/Profile';
import Settings from './components/Settings/Settings';
import Savedpage from './components/Savedpage/Savedpage';
import Reviews from './components/Review/Reviews';
import Editprofile from './components/Editprofile/Editprofile';
import Rateandreview from './components/Rateandreview/Rateandreview';
import Enquiry from './components/Enquiry/Enquiry';
import Categories from './components/Categories/Categories';
import Userinput from './components/Userinput/Userinput';
import Privacypolicy from './components/Privacypolicy/Privacypolicy';
import Termsandcondition from './components/Termsandcondition/Termsandcondition';
import Community from './components/Community/Community';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login/>} /> // Default route for homepage
        <Route path="/login" element={<Login/>} /> 
        <Route path="/homepage" element={<Homepage />}/>
        <Route path="/detailpage" element={<Detailpage />}/>
        <Route path="/profile" element={<Profile />}/>
        <Route path="/settings" element={<Settings />}/>
        <Route path="/savedpage" element={<Savedpage />}/>
        <Route path="/rateandreview" element={<Rateandreview />}/>
        <Route path="/reviews" element={<Reviews />}/>
        <Route path="/editprofile" element={<Editprofile />}/>
        <Route path="/enquiry" element={<Enquiry />}/>
        <Route path="/categories" element={<Categories />}/>
        <Route path="/userinput" element={<Userinput />}/>
        <Route path="/privacypolicy" element={<Privacypolicy />}/>
        <Route path="/termsandcondition" element={<Termsandcondition />}/>
        <Route path="/community" element={<Community />}/>
        {/* <Route path="/usernewpage" element={<Userinput/>}/> */}

      </Routes>
    </Router>
  );
}

export default App;
