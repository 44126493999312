import React from "react";
import '../Termsandcondition/Termsandcondition.css'
import { useNavigate } from 'react-router-dom';

const Termsandcondition = () => {
    const navigate = useNavigate();

    return (
        <div className="privacycontainer">
        <div className="privacy_backbutton">
            <button onClick={() => navigate(-1)} className="privacy_review-back-button">
                <i class="fa-solid fa-arrow-left"></i>
            </button>
        </div>
        <div className="privacy_view_container">
            <h2 className="privacy_profile_head">Terms and condition</h2>
        </div>
        </div>
        );

    };
    export default Termsandcondition;