import React from "react";
import '../Carousel/Carousel.css'
const Carousel = () => {
    return (
        <div class="container1">
          <div class="slide12">
          <img src="/cover.svg"/>
          {/* <img src="https://images.unsplash.com/photo-1520854221256-17451cc331bf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/> */}
          {/* <img src="/cover.svg"/>
          <img src="/cover.svg"/> */}
          </div>
        </div>

        );

    };
    export default Carousel;