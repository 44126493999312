import React, { useState, useEffect } from "react";
import '../Categories/Categories.css';
import { useLocation } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore"; // Import Firestore functions
import { firebaseConfig } from '../firebaseConfig';

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Categories = () => {
    const location = useLocation();
    const categoryName = location.state.categoryName; // Get categoryName from state
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                // Query companies where categoryType matches the categoryName
                const q = query(collection(db, "company"), where("categoryType", "==", categoryName));
                const querySnapshot = await getDocs(q);
                const companiesData = querySnapshot.docs.map(doc => doc.data());
                setCompanies(companiesData);
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
        };

        fetchCompanies();
    }, [categoryName, db]);

    return (
        <div className="categorycontainer">
            <div className="category_view_container">
                <h2 className="enquiry_profile_head">{categoryName}</h2>
            </div>
            <div className="category_companies">
                {companies.map((company, index) => (
                    <div key={index} className="company-container">
                        <div className="company-logo">
                            <img className="iconcategory" src={company.logo} alt={company.companyName} />
                        </div>
                        <div className="company-info">
                            <div className="company-name">{company.companyName}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Categories;
