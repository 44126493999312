import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import '../Login/Login.css'
import { FcGoogle } from "react-icons/fc";
import { FaApple } from 'react-icons/fa';
import '@fortawesome/fontawesome-free/css/all.css';
import { getFirestore, doc, setDoc, serverTimestamp, addDoc, collection } from 'firebase/firestore'; // Import serverTimestamp

const firebaseConfig = {
  apiKey: "AIzaSyB1_W8alwqc9oAgmoc3WSLC23u9vSAEyVo",
  authDomain: "dreamin-cdabe.firebaseapp.com",
  projectId: "dreamin-cdabe",
  storageBucket: "dreamin-cdabe.appspot.com",
  messagingSenderId: "646690260914",
  appId: "1:646690260914:web:d423fe34ae374d41f206ab",
  measurementId: "G-JF31KV8Q15"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore


const Login = () => {

  const [deviceType, setDeviceType] = useState('Other');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      setDeviceType('iOS');
    } else if (/Android/.test(userAgent)) {
      setDeviceType('Android');
    }
  }, []);



  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      console.log("Google login successful", result);

      // Get user info
      const user = result.user;
      const email = user.email;
      const imageUrl = user.photoURL;
      const phoneNumber = user.phoneNumber
      const customerUid = user.uid; // Use the user's UID as the unique identifier
      


      // Add user info to Firestore and get the document ID
      const docRef = await addDoc(collection(db, "customer"), {
        email: email,
        phoneNumber: phoneNumber,
        imageUrl: imageUrl,
        createdAt: serverTimestamp(), // Set createdAt to the current server timestamp  
        isDeleted: false, // Set isDeleted to false by default
        lastSeen: serverTimestamp(),
        customerUid: user.uid,
        status: "offline" // Default value for status
      });

      // Get the document ID
      const docId = docRef.id;

      // Update the document to include the customerId field
      await setDoc(doc(db, "customer", docId), {
        customerId: docId, // Set customerId to the document ID
      }, { merge: true }); // Use merge: true to update the existing document without overwriting other fields

      window.location.href = '/homepage';
    } catch (error) {
      console.error("Google login failed", error);
    }
  };

  const handleAppleLogin = () => {
    // Handle Apple login
    // This function will only be called if the user is on an iOS device
  };


  return (

    <div className="page">
      <div className='logo-container'>
        <img src='/dreamin_logo.svg' alt="Dreamin Logo" />
      </div>
      <div className="text-container">
        <div className="text-content">
          {deviceType !== 'iOS' && (
            <button className="button login__submit" onClick={handleGoogleLogin}>
              <span className="button__text">Log in with Google <FcGoogle style={{ fontSize: '20px', verticalAlign: 'middle', marginLeft: '5px', marginBottom: '4px' }} /></span>
            </button>
          )}
          <p className='Option'></p>
          {deviceType === 'iOS' && (
            <button className="button login__submit1" onClick={handleGoogleLogin}>
              <span className="button__text">Log in with Apple <FcGoogle style={{ fontSize: '20px', verticalAlign: 'middle', marginLeft: '5px', marginBottom: '4px' }} /></span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
