import React from "react";
import '../Settings/Settings.css';
import { useNavigate } from 'react-router-dom';

// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const Settings = () => {

    const navigate = useNavigate();

    const handleEditprofileClick = () => {
        navigate('/editprofile');
    };

    const handleBackClick = () => {
        navigate(-1); // Navigate back to the previous page
    };


    return (
        <div className="">
            <div className="profile_container">
                <div className="heading-container">
                    <button onClick={handleBackClick} className="back-button">
                        <i class="fa-solid fa-angle-left"></i>
                    </button>
                    <h2 className="profile_head">Settings</h2>
                </div>
            </div>
            <div className="centered-container">
            <div className="drawer_settings">

                <div className="settingspage">
                    <div class="editprofile" onClick={handleEditprofileClick}>
                        <span class="edit-text">Edit profile</span>
                        <i class="edit-arrow fa-regular fa-pen-to-square"></i>
                    </div>
                </div>

                <div className="settingspage1">
                    <div class="notification">
                        {/* <i class="notificationicon fa-regular fa-bell"></i> */}
                        <span class="notification-text">Notification</span>
                        <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                        </label>
                        {/* <i class="notification-arrow fa-regular fa-bell"></i> */}
                    </div>
                </div>

                <div className="settingspage1">
                    <div class="notification">
                        {/* <i class="notificationicon fa-regular fa-bell"></i> */}
                        <span class="notification-text">Delete Account</span>
                        <i class="notification-arrow fa-regular fa-trash-can"></i>
                    </div>
                </div>

</div>
            </div>
        </div>
    );

};
export default Settings;