import React from "react";
import '../Detailpage/Detailpage.css'
import { useNavigate } from 'react-router-dom';
const Detailpage = () => {

    const navigate = useNavigate();

    const handleEnquiryClick = () => {
        navigate('/enquiry'); 
    }

    return (
        <div className="detailbox">
            <div className="detail_image">
                <img src='https://firebasestorage.googleapis.com/v0/b/dreamin-cdabe.appspot.com/o/cover%20Images%2F1713433550551.jpg?alt=media&token=821144ae-6c32-450b-a3c2-c3c5b3897bcd' alt="Dreamin Logo" className="image-detail" />
            </div>
            <div className="detail-header">
                <h3 className="detail-companyname">Oakstreet Wedding Planner</h3>
                <p>kochi kerala</p><br></br>
                <div class="reviewstars">⭐ 4.5 · 1,234 reviews</div>
            </div>
            {/* <div className="">
                            <img src='/photography.svg' alt="Dreamin Logo" className="detail_logo"/>
                        </div> */}
            <div className="desc-box">
                <div className="company-desc">
                    <p>
                        Oakstreet Wedding Planner, where dreams meet meticulous planning and creativity blossoms into unforgettable moments. Our boutique firm specializes in crafting unique, personalized weddings that reflect the essence of each couple.
                    </p>
                </div>

                <div className="ctbutton">
                    <button class="btn"><i class="animation"></i>Contact Us<i class="animation"></i>
                    </button>

                    <button class="btn11"onClick={handleEnquiryClick}><i class="animation"></i>Enquire<i class="animation"></i>
                    </button>
                </div>

                <div className="features">
                    <h1 className="offerhead">What we offers</h1><br></br>
                    <p>With a team of seasoned professionals, we seamlessly orchestrate every detail, ensuring your special day is a masterpiece. From venue selection to design, entertainment, and logistics, we handle it all, allowing you to savor every moment.</p>
                </div>

                <div className="recent">
                    <h1 className="recenthead">Recent works</h1><br></br>
                    <p>With a team of seasoned professionals, we seamlessly orchestrate every detail, ensuring your special day is a masterpiece. From venue selection to design, entertainment, and logistics, we handle it all, allowing you to savor every moment.</p>
                </div>

                <div className="recent">
                    <h1 className="recenthead">Why us ?</h1><br></br>
                    <p>We believe in the power of love stories and strive to make your wedding day as extraordinary as your journey together. Join us on this enchanting adventure, where your vision comes to life, and celebrations become cherished memories.</p>
                </div> <br></br>

                <div className="social">

                </div>

                <div class="icons-container">
                    <i class="icon fas fa-globe"></i>
                    <i class="icon fab fa-google"></i>
                    <i class="icon fab fa-instagram"></i>
                    <i class="icon fab fa-whatsapp"></i>
                    <i class="icon fab fa-facebook-f"></i>
                </div>
            </div>
        </div>
    );

};
export default Detailpage;