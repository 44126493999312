import React from "react";
import '../Community/Community.css';
import { useNavigate } from 'react-router-dom';

const Community = () => {
    const navigate = useNavigate();

    return (
        <div className="communitycontainer">
        <div className="community_backbutton">
            <button onClick={() => navigate(-1)} className="community_review-back-button">
                <i class="fa-solid fa-arrow-left"></i>
            </button>
        </div>
        <div className="community_view_container">
            <h2 className="community_profile_head">Community guidelines</h2>
        </div>
        </div>
        );

    };
    export default Community;