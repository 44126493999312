import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth"; // Import onAuthStateChanged
import { firebaseConfig } from '../firebaseConfig';
import '../Profile/Profile.css';
import Hamburger from "../Hamburger/Hamburger";
import { useNavigate } from 'react-router-dom';


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Auth

const Profile = () => {
    const [profileData, setProfileData] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "customer"));
                const customerData = querySnapshot.docs.map(doc => doc.data())[0];
                setProfileData(customerData);
            } catch (error) {
                console.error("Error fetching profile data:", error);
            }
        };

        fetchProfileData();

        // Listen for changes in the authentication state
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (!user) {
                // User is signed out, redirect to login page
                navigate('/login');
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [db, navigate]);

    const handleSettingsClick = () => {
        navigate('/settings'); // Replace '/settings' with the path to your settings component
    }
    const handlePrivacyClick = () => {
        navigate('/privacypolicy'); // Replace '/settings' with the path 
    }
    const handleTermsClick = () => {
        navigate('/Termsandcondition'); // Replace '/settings' with the path 
    }
    const handleCommunityClick = () => {
        navigate('/community'); // Replace '/settings' with the path 
    }
    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login'); // Redirect to login page after logout
        } catch (error) {
            console.error("Error signing out:", error);
        }
    }

    return (
        <div className="">
            <div className="profile_container">
                <Hamburger />
                <h2 className="profile_head">Profile</h2>
                <div className="profile_image_container">
                    {profileData && <img src={profileData.imageUrl} alt="Profile" className="profile_image" />}
                </div>
                <h2 className="profile_name">{profileData?.name}</h2>
                <p className="phone">{profileData?.phoneNumber}</p>
            </div>
            <div className="centered-container">
            <div className="appdrawer">
                <div className="settings1">
                    < div class="menu-item" onClick={handleSettingsClick}>
                        <i class="menu-icon fas fa-cog"></i>
                        <span class="menu-text">Settings</span>
                        <i class="menu-arrow fas fa-chevron-right"></i>
                    </div>
                </div>

                <div className="help">
                    <div class="menu-item1">
                        <i class="menu-icon1 fa-solid fa-circle-question"></i>
                        <span class="menu-text1">Help</span>
                        <i class="menu-arrow1 fas fa-chevron-right"></i>
                    </div>
                </div>

                <div className="settings">
                    <div class="menu-item2"onClick={handleTermsClick}>
                        <i class="menu-icon2 fa-solid fa-file-contract"></i>
                        <span class="menu-text2">Terms and conditions</span>
                        <i class="menu-arrow2 fas fa-chevron-right"></i>
                    </div>
                </div>

                <div className="settings">
                    <div class="menu-item3" onClick={handlePrivacyClick}>
                        <i class="menu-icon3 fa-solid fa-lock"></i>
                        <span class="menu-text3">Privacy policy</span>
                        <i class="menu-arrow3 fas fa-chevron-right"></i>
                    </div>
                </div>

                <div className="settings2">
                    <div class="menu-item4" onClick={handleCommunityClick}>
                        <i class="menu-icon4 fa-regular fa-file"></i>
                        <span class="menu-text4">Community guidelines </span>
                        <i class="menu-arrow4 fas fa-chevron-right"></i>
                    </div>
                </div>


                <div className="logout">
                    <div class="menu-item" onClick={handleLogout}>
                        <i class="menu-icon fa-solid fa-arrow-right-from-bracket"></i>
                        <span class="menu-text">Logout</span>
                        <i class="menu-arrow fas fa-chevron-right"></i>
                    </div>
                </div>
</div>
            </div>
        </div>
    );
};

export default Profile;

