import React, { useState } from "react";
import '../Enquiry/Enquiry.css';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, serverTimestamp } from "firebase/firestore"; // Import Firestore functions
import { firebaseConfig } from '../firebaseConfig';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Enquiry = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        whatsappNumber: '',
        address: '',
        dateOfEvent: '',
        typeOfWedding: '',
        budget: '',
        message: '',
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, "customerEnquiry"), {
                ...formData,
                enquiryCreatedTime: serverTimestamp(),
            });
            alert("Enquiry submitted successfully!");
            navigate('/enquiry'); // Redirect to home page or any other page after submission
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <div className="enquirycontainer">
            <div className="enquiry_backbutton">
                <button onClick={() => navigate(-1)} className="enquiry_review-back-button">
                    <i class="fa-solid fa-arrow-left"></i>
                </button>
            </div>
            <div className="enquiry_view_container">    
                <h2 className="enquiry_profile_head">Enquire</h2>
            </div>
            <form onSubmit={handleSubmit} className="enquiryfield">
                <div class="input-field">
                    <input required="" name="name" type="text" onChange={handleInputChange} />
                    <label>Your Name</label>
                </div>

                <div class="input-field">
                    <input required="" name="whatsappNumber" type="text" onChange={handleInputChange} />
                    <label>Whatsapp number</label>
                </div>

                <div class="input-field">
                    <input required="" name="address" type="text" onChange={handleInputChange} />
                    <label>Address</label>
                </div>

                <div class="input-field">
                    <input required="" name="dateOfEvent" type="date" onChange={handleInputChange} />
                    <label>Date of event</label>
                </div>

                <div class="input-field">
                    <input required="" name="typeOfWedding" type="text" onChange={handleInputChange} />
                    <label>Wedding type</label>
                </div>

                <div class="input-field">
                    <input required="" name="budget" type="text" onChange={handleInputChange} />
                    <label>Budget</label>
                </div>

                <div class="input-field">
                    <input required="" name="message" type="text" onChange={handleInputChange} />
                    <label>Message</label>
                </div>

                <button type="submit" className="enquiry_button"> Submit</button>
            </form>
        </div>
    );
};

export default Enquiry;
