import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { firebaseConfig } from '../firebaseConfig';
import '../Buisnesscard/Buisnesscard.css';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Buisnesscard = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "homeOffers"));
                const offersData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setOffers(offersData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching offers:", error);
            }
        }; 

        fetchOffers();
    }, [db]);

    const currentDate = new Date(); // Get current date

    return (
        <div className="desc">
            <div className="offer">
                <p className="offertext">Offers and recommendations</p>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                offers.map((offer, index) => {
                    const endDate = offer.endDate.toDate(); // Convert Firestore Timestamp to Date object
                    // Check if the current date is before the end date
                    if (currentDate < endDate) {
                        return (
                            <div key={index} className="cardesc">
                                <img src={offer.imageUrl} alt={offer.name} className="card-desc-image" />
                            </div>
                        );
                    } else {
                        return null; // Return null if the offer has expired
                    }
                })
            )}
        </div>
    );
};

export default Buisnesscard;
