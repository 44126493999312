import React from "react";
import '../Savedpage/Savedpage.css';

const Savedpage = () => {
    return (
<div className="">
<div className="profile_container">
        <h2 className="profile_head">Saved</h2>
        </div>
        <div class="saved">
        <div className="saved_image_container">
                    <img src='/jewellery.svg' alt="Dreamin Logo" className="saved_image" />      
                </div>
                <div class="companyname">
                    <p class="cname">Oakstreet Wedding Planner</p>
                    </div>
                    <img src='/saved.svg' alt="Dreamin Logo" className="savedicons" />
        </div>
</div>
        );

    };
    export default Savedpage;