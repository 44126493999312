import React from "react";
import '../Editprofile/Editprofile.css';

const Editprofile = () => {
  return (
    <div class="">
      <div className="profile_container">
        <h2 className="profile_head">Edit profile</h2>
      </div>
      <div class="input-container">
        <label for="name" class="name">Name:</label>
        <input placeholder="Enter your name" type="text" class="input" />
        <div class="underline"></div>
      </div>

      <div class="input-container">
        <label for="name" class="name">Email:</label>
        <input placeholder="Enter your name" type="text" class="input" />
        <div class="underline"></div>
      </div>

      <div class="input-container">
        <label for="name" class="name">Contact</label>
        <input placeholder="Enter your name" type="text" class="input" />
        <div class="underline"></div>
      </div>


    </div>
  );
};
export default Editprofile;