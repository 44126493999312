import React from "react";
import '../Review/Reviews.css';

const Reviews = () => {
    return (

 <div className="">
         <div className="reviews_container">
          <h2 className="reviews_head">Rate and review</h2>
          </div>  
       <div className="ratingdiv">
        <h2 className="review_start">4.0</h2>
        </div>
        <div class="rating-starsdiv">
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="far fa-star"></i>
        </div>
        <div className="addreview_button">
        <button class="add-review-btn">Add Review</button>
        </div>
        </div>   

        );

    };
    export default Reviews;