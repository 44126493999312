import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { firebaseConfig } from '../firebaseConfig';
import '../Homepage/Homepage.css';
import Bottombar from '../Bottombar/Bottombar';
import Loader from '../Loader/Loader';
import Buisnesscard from '../Buisnesscard/Buisnesscard';
import Carousel from '../Carousel/Carousel';
import Hamburger from '../Hamburger/Hamburger';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);



const Homepage = () => {
    const navigate = useNavigate(); // Use useNavigate
    const [categories, setCategories] = useState([]);
    const [initialCategories, setInitialCategories] = useState([]);
    const [showMoreTiles, setShowMoreTiles] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleMoreClick = () => {
        setShowMoreTiles(!showMoreTiles);
    };

    useEffect(() => {
        // Simulate loading delay (you can replace this with actual data fetching)
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 2000);

        // Cleanup function
        return () => clearTimeout(timeout);
    }, []);


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'categories'));
                const categoriesData = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    console.log('Category Data:', data); // Log category data
                    return {
                        id: doc.id,
                        categoryName: data.categoryName,
                        ...data
                    };
                });
                setInitialCategories(categoriesData.slice(0, 5));
                setCategories(categoriesData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, [db]);

    const handleCardClick = (categoryName) => {
        navigate('/categories', { state: { categoryName } }); // Navigate to category page and pass categoryName
    };



    return (
        <div className="page">
            {loading ? (
                <Loader />
            ) : (
                <> 
                    <div>
                        <nav className='navbar'>
                            <Hamburger />
                            <p className='navhead'>Dreamin</p>
                            {/* <img src='/notification.svg' alt="Dreamin Logo" /> */}
                        </nav>
                    </div>
                    <div className='text1'>
                        <p className='tagline'>Your Perfect<br></br> Wedding Awaits!</p>
                    </div>
                    <br></br>
                    <Carousel />
                    <br></br>
                    <div className="card-container">
                        {(showMoreTiles ? categories : initialCategories).map(category => (
                            <div className="card" key={category.id} onClick={() => handleCardClick(category.categoryName)}>
                                <div className="image-container">
                                    <img src={category.imageUrl} alt={category.name} />
                                    <p className="category-name">{category.categoryName}</p>
                                </div>
                            </div>
                        ))}
                        <div className="card" onClick={handleMoreClick}>
                            <img src={showMoreTiles ? '/less.svg' : '/photography.svg'} alt={showMoreTiles ? 'less' : 'more'} />

                        </div>
                    </div>
                    <Buisnesscard />

                </>
            )}
        </div>

    );
};

export default Homepage;