import '../Hamburger/Hamburger.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Hamburger = ({ modal }) => {
  const [isMenuVisible, setMenuVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 100; // Adjust this value based on when you want to hide the menu

      // Toggle visibility based on scroll position
      setMenuVisible(window.scrollY < scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollBOttom = () => {
    setMenuVisible(false)
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth' // optional, for smooth scrolling
    });
  }



  return (

    <div className={`hamburger-menu ${isMenuVisible ? '' : 'hidden'}`}>
      <input id="menu__toggle" type="checkbox" />
      <label class="menu__btn" for="menu__toggle">
        <span></span>
      </label>

      <ul class="menu__box">
        {/* <img src={bg8image} alt="Desktop" className="menuboxlogo" /> */}
        <li><Link to="/homepage" className="menu__item">Home</Link></li>
        <li><a class="menu__item" href="#">Chat</a></li>
        <li><Link to="/savedpage" className="menu__item">Saved</Link></li>
        <li><Link to="/profile" className="menu__item">Profile</Link></li>

        {/* <li><a class="menu__item" href="#">About</a></li> */}
        {/* <li><Link className='menu__item' onClick={()=> {setMenuVisible(false); document.getElementById("menu__toggle").click()}}>Explore</Link></li>
      <li><Link className='menu__item' onClick={handleScrollBOttom}>Contact</Link></li>
      <li><Link className='menu__item' onClick={handleMOdal}>Join</Link></li> */}
        <li>
          <div style={{

            height: "20px",
            width: "280px",
            gap: "10px",
            paddingLeft: "20px"
          }}>

            {/* <button 
        
       
        style={{
          width:"40px",
          height:"40px",
          borderRadius:"50%",
          padding:"4px",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          background:"none"
        }}
        >
            <img
                    style={{
                      width:"25px",
                      height:"34px",
                      zIndex:"1000"
                    }}
            className='w-2 h-2' src={bg9image} alt="LeetCode" />
        </button>


    <button 
        
        onClick={()=> window.open("https://www.linkedin.com/company/emartapp", "_blank")} 
        style={{
          width:"40px",
          height:"40px",
          borderRadius:"50%",
          padding:"4px",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          background:"red"
        }}
        >
            <img
                    style={{
                      width:"25px",
                      height:"25px",
                      zIndex:"1000"
                    }}
            className='w-2 h-2' src={linkedIn} alt="LeetCode" />
        </button>
    <button 
        
        onClick={()=> window.open("http://www.instagram.com/emartapp.co", "_blank")} 
        style={{
          width:"40px",
          height:"40px",
          borderRadius:"50%",
          padding:"4px",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          background:"red"
        }}
        >
            <img
                    style={{
                      width:"25px",
                      height:"25px",
                      zIndex:"1000"
                    }}
            className='w-2 h-2' src={insta} alt="LeetCode" />
        </button>

        <button 
  onClick={() => window.location.href = 'mailto:contact@emartapp.com'} 
  style={{
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "red",
    cursor: "pointer", // Add cursor style to indicate the button is clickable
  }}
>
  <img
    style={{
      width: "25px",
      height: "25px",
      zIndex: "1000"
    }}
    className='w-2 h-2' 
    src={gmail}
    alt="LeetCode"
  />
</button>


<button 
  onClick={() => window.location.href = 'https://wa.me/+16124281616'} 
  style={{
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "red",
    cursor: "pointer", // Add cursor style to indicate the button is clickable
  }}
>
  <img
    style={{
      width: "25px",
      height: "25px",
      zIndex: "1000"
    }}
    className='w-2 h-2' 
    src={whatsapp}
    alt="WhatsApp"
  />
</button>
 */}

          </div>
        </li>
      </ul>

    </div>
  );
};


export default Hamburger;